import React from "react";
import Head from "next/head";
import _ from "lodash";
import { usePageContext } from "@providers/PageProvider";
import { useRouter } from "next/router";
// import getter from '@services/StrapiService/getter';
import helper from "@utils/helper";
const interpolateContent = (
  content: any,
  { seoData, router, resolvedUrl }: any
) => {
  let metaUrl = _.get(router, "location.href", resolvedUrl);
  if (metaUrl && !helper.isExternal(metaUrl))
    metaUrl = `${process.env.DOMAIN}${metaUrl}`;
  try {
    return _.template(content, {
      interpolate: /{{([\s\S]+?)}}/g,
    })({
      ...seoData,
      metaUrl,
    });
  } catch (err) {
    return content;
  }
};
const View = () => {
  const { pageData, resolvedUrl } = usePageContext();
  const metaItems = [
    /* <!-- Schema.org markup for Google+ --> */
    {
      content: "{{metaTitle}}",
    },
    {
      name: "description",
      content: "{{metaDescription}}",
    },
    {
      content: "{{metaImage}}",
    },
    {
      name: "keywords",
      content: "{{metaKeywords}}",
    },
    // <!-- Twitter Card data -->
    {
      name: "twitter:card",
      content: "{{metaType}}",
    },
    {
      name: "twitter:site",
      content: "",
    },
    {
      name: "twitter:title",
      content: "{{metaTitle}}",
    },
    {
      name: "twitter:description",
      content: "{{metaDescription}}",
    },

    {
      name: "twitter:image",
      content: "{{metaImage}}",
    },

    // <!-- Open Graph data -->
    {
      property: "og:title",
      content: "{{metaTitle}}",
    },
    {
      property: "og:type",
      content: "{{metaType}}",
    },
    {
      property: "og:url",
      content: "{{metaUrl}}",
    },
    {
      property: "og:image",
      content: "{{metaImage}}",
    },
    {
      property: "og:description",
      content: "{{metaDescription}}",
    },
    {
      property: "og:site_name",
      content: "",
    },
    {
      property: "og:price:amount",
      content: "",
    },
    {
      property: "og:price:currency",
      content: "",
    },
  ];
  const router = useRouter();
  const seoData = helper.formatMetatags(pageData);
  // console.log({ pageData, seoData });
  return (
    <Head>
      <title>{_.get(seoData, "metaTitle", "")}</title>
      {/* <script
        defer={true}
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KRC5WFT');`,
        }}
      ></script> */}

      <link rel="icon" href="/favicon.svg" />
      {metaItems.map((item, index) => {
        let { content, ...res } = item;
        content = interpolateContent(content, { seoData, router, resolvedUrl });
        if (!content) return null;
        return <meta key={`${index}`} {...res} content={content} />;
      })}
    </Head>
  );
};
export default View;
