import Head from "next/head";

const View = () => {
  return (
    <Head>
      <script src="https://sp.zalo.me/plugins/sdk.js" async></script>
    </Head>
  );
};
export default View;
