const View = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <g clipPath="url(#clip0_7245_2019)">
        <path
          d="M15.082 12.5C15.082 13.7686 14.0537 14.7969 12.7852 14.7969C11.5166 14.7969 10.4883 13.7686 10.4883 12.5C10.4883 11.2314 11.5166 10.2031 12.7852 10.2031C14.0537 10.2031 15.082 11.2314 15.082 12.5Z"
          fill="#2E281F"
        />
        <path
          d="M18.1575 8.43671C18.0471 8.13751 17.8709 7.8667 17.642 7.64441C17.4197 7.41553 17.1491 7.23938 16.8497 7.12897C16.6069 7.03467 16.2422 6.92242 15.5704 6.89185C14.8436 6.8587 14.6257 6.85156 12.7859 6.85156C10.9459 6.85156 10.728 6.85852 10.0014 6.89166C9.32959 6.92242 8.96466 7.03467 8.72205 7.12897C8.42267 7.23938 8.15186 7.41553 7.92975 7.64441C7.70087 7.8667 7.52472 8.13733 7.41412 8.43671C7.31982 8.6795 7.20758 9.04443 7.177 9.71625C7.14386 10.4428 7.13672 10.6607 7.13672 12.5007C7.13672 14.3406 7.14386 14.5585 7.177 15.2852C7.20758 15.957 7.31982 16.3218 7.41412 16.5646C7.52472 16.864 7.70068 17.1346 7.92957 17.3569C8.15186 17.5858 8.42249 17.7619 8.72186 17.8723C8.96466 17.9668 9.32959 18.079 10.0014 18.1096C10.728 18.1428 10.9457 18.1497 12.7857 18.1497C14.6259 18.1497 14.8438 18.1428 15.5702 18.1096C16.242 18.079 16.6069 17.9668 16.8497 17.8723C17.4507 17.6405 17.9257 17.1655 18.1575 16.5646C18.2518 16.3218 18.364 15.957 18.3948 15.2852C18.4279 14.5585 18.4349 14.3406 18.4349 12.5007C18.4349 10.6607 18.4279 10.4428 18.3948 9.71625C18.3642 9.04443 18.252 8.6795 18.1575 8.43671ZM12.7859 16.0389C10.8316 16.0389 9.24738 14.4548 9.24738 12.5005C9.24738 10.5463 10.8316 8.96222 12.7859 8.96222C14.74 8.96222 16.3242 10.5463 16.3242 12.5005C16.3242 14.4548 14.74 16.0389 12.7859 16.0389ZM16.4641 9.64923C16.0074 9.64923 15.6372 9.27899 15.6372 8.82233C15.6372 8.36566 16.0074 7.99542 16.4641 7.99542C16.9208 7.99542 17.291 8.36566 17.291 8.82233C17.2908 9.27899 16.9208 9.64923 16.4641 9.64923Z"
          fill="#2E281F"
        />
        <path
          d="M12.7852 0.5C6.15875 0.5 0.785156 5.8736 0.785156 12.5C0.785156 19.1264 6.15875 24.5 12.7852 24.5C19.4116 24.5 24.7852 19.1264 24.7852 12.5C24.7852 5.8736 19.4116 0.5 12.7852 0.5ZM19.6342 15.3409C19.6009 16.0744 19.4843 16.5752 19.314 17.0135C18.956 17.9391 18.2243 18.6708 17.2987 19.0288C16.8605 19.1991 16.3596 19.3156 15.6262 19.3491C14.8914 19.3826 14.6567 19.3906 12.7853 19.3906C10.9138 19.3906 10.6793 19.3826 9.94427 19.3491C9.21094 19.3156 8.70996 19.1991 8.27179 19.0288C7.81183 18.8558 7.39545 18.5846 7.05121 18.2339C6.70074 17.8899 6.42957 17.4733 6.25653 17.0135C6.08624 16.5754 5.9696 16.0744 5.93628 15.3411C5.9024 14.6061 5.89453 14.3713 5.89453 12.5C5.89453 10.6287 5.9024 10.3939 5.9361 9.65912C5.96942 8.9256 6.08588 8.4248 6.25616 7.98645C6.4292 7.52667 6.70056 7.11011 7.05121 6.76605C7.39526 6.41541 7.81183 6.14423 8.27161 5.97119C8.70996 5.8009 9.21075 5.68445 9.94427 5.65094C10.6791 5.61743 10.9138 5.60938 12.7852 5.60938C14.6565 5.60938 14.8912 5.61743 15.626 5.65112C16.3596 5.68445 16.8604 5.8009 17.2987 5.97101C17.7585 6.14404 18.175 6.41541 18.5193 6.76605C18.8698 7.11029 19.1411 7.52667 19.314 7.98645C19.4844 8.4248 19.6009 8.9256 19.6344 9.65912C19.6679 10.3939 19.6758 10.6287 19.6758 12.5C19.6758 14.3713 19.6679 14.6061 19.6342 15.3409Z"
          fill="#2E281F"
        />
      </g>
      <defs>
        <clipPath id="clip0_7245_2019">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.785156 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default View;
