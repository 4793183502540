import APIService from "@utils/apiService";
import _ from "lodash";
import cx from "classnames";
class Service {
  config: any;
  constructor(config: any) {
    this.config = config;
  }
  set(key: string, value: any) {
    _.set(this.config, key, value);
  }
  async find(query: any) {
    // TODO Query.
    const handler: any = {
      string: async (id: string) => {
        return await APIService.get(`/api/${_.get(this.config, "id")}/${id}`);
      },
      number: async (id: string) => {
        return await APIService.get(`/api/${_.get(this.config, "id")}/${id}`);
      },
      object: async ({ id, ...params }: any) => {
        return await APIService.get(
          `/api/${_.get(this.config, "id")}/${cx(id)}`,
          {
            params,
          }
        );
      },
    };
    const typeQuery = typeof query;
    if (_.isFunction(handler[typeQuery]))
      return await handler[typeQuery](query);
    return null;
  }
  async search(urlQuery: string) {
    const res = await APIService.get(
      `/api/${_.get(this.config, "id")}?${urlQuery}`
    );
    return res;
  }
}
const Model = new Service({});

export default Model;
