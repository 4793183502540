const View = () => {
  return (
    <svg width="27" height="25" viewBox="0 0 27 25" fill="none">
      <g clipPath="url(#clip0_7245_2023)">
        <path
          d="M26.4994 12.5C26.4994 5.87188 20.7439 0.5 13.6423 0.5C6.54074 0.5 0.785156 5.87188 0.785156 12.5C0.785156 19.1281 6.54074 24.5 13.6423 24.5C13.7176 24.5 13.793 24.5 13.8683 24.4953V15.1578H11.106V12.1531H13.8683V9.94062C13.8683 7.37656 15.5458 5.97969 17.9967 5.97969C19.1719 5.97969 20.1814 6.05938 20.4727 6.09688V8.77813H18.7852C17.4542 8.77813 17.1931 9.36875 17.1931 10.2359V12.1484H20.3823L19.9654 15.1531H17.1931V24.0359C22.567 22.5969 26.4994 17.9797 26.4994 12.5Z"
          fill="#2E281F"
        />
      </g>
      <defs>
        <clipPath id="clip0_7245_2023">
          <rect
            width="25.7143"
            height="24"
            fill="white"
            transform="translate(0.785156 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default View;
