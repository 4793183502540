import styles from "./StayInspired.module.scss";
import cx from "classnames";
import Image from "@components/Image";
import Instagram from "@components/Icons/Instagram";
import Facebook from "@components/Icons/Facebook";
import InstagramDark from "@components/Icons/InstagramDark";
import FacebookDark from "@components/Icons/FacebookDark";
import map from "lodash/map";
import slice from "lodash/slice";
import get from "lodash/get";
import castArray from "lodash/castArray";
import Link from "@components/Link";
import { usePageContext } from "@providers/PageProvider";
import helper from "@utils/helper";
import renderPropsComposer from "@utils/renderPropsComposer";
import usePromise from "@hooks/usePromise";
import StrapiService from "@services/StrapiService";
import { useTranslationContext } from "@providers/TranslationProvider";

const renderIcons = (data: any, type?: string) => {
  return map(castArray(data), (icon, index) => {
    return renderPropsComposer(
      {
        matcher: ({ type }: any) => type === "instagram",
        render: ({ href }: any) => (
          <Link
            key={`icon_${index}`}
            href={href}
            target="_blank"
            rel="noopener"
            ariaLabel={"amanaki instagram"}
          >
            {type === "dark" && <InstagramDark />}
            {type !== "dark" && <Instagram />}
          </Link>
        ),
      },
      {
        matcher: ({ type }: any) => type === "facebook",
        render: ({ href }: any) => (
          <Link
            href={href}
            target="_blank"
            rel="noopener"
            key={`icon_${index}`}
            ariaLabel={"amanaki facebook"}
          >
            {type === "dark" && <FacebookDark />}
            {type !== "dark" && <Facebook />}
          </Link>
        ),
      }
    )(icon);
  });
};
const renderImages = (data: any) => {
  return map(data, (item, index) => {
    return (
      <Link
        href={get(item, "icon.href")}
        key={`image_${index}`}
        target="_blank"
        rel="noopener"
        ariaLabel={get(item, "image.alt") || "amanaki image"}
      >
        <div className={cx(styles.wrapperImage)}>
          <Image
            scale={1.1}
            key={`img_${index}`}
            wrapperProps={{ className: styles.wrapper }}
            className={styles.image}
            src={get(item, "image.src", "")}
            alt={get(item, "image.alt", "")}
            layout="fill"
            objectFit="cover"
          />
          <div className={cx(styles.icon)}>
            {!!(get(item, "icon.type") === "instagram") && <Instagram />}
            {!!(get(item, "icon.type") === "facebook") && <Facebook />}
          </div>
        </div>
      </Link>
    );
  });
};
const View = ({ mode = "", border = "" }: any) => {
  const { i18n } = useTranslationContext();
  const { hotel } = usePageContext();
  const [data] = usePromise(async () => {
    const id: any = {
      "amanaki-thao-dien": "hotel10-stay-connected",
      "amanaki-boutique-saigon": "hotel65-stay-connected",
    };
    const hotelId = hotel ?? "amanaki-thao-dien";
    StrapiService.set("id", id[hotelId]);
    const resInspired = await StrapiService.find({
      populate: "deep",
      locale: i18n.language,
    });
    return get(resInspired, "data", {});
  });
  const stayConnected = helper.formatStayConnected({ stayConnected: data });

  return (
    <section
      data-scroll-section
      className={cx("", styles.section, styles[mode])}
    >
      <div className={cx("divider", { ["hidden"]: border === "none" })}></div>
      <div className={cx("inner-large", styles.boxTitle)}>
        <h2 className="ama-section-title">{get(stayConnected, "title", "")}</h2>
        <div className={cx(styles.groupSocial, styles.mobile)}>
          {renderIcons(get(stayConnected, "icons"))}
        </div>
        <div className={cx(styles.groupSocialDark, styles.mobile)}>
          {renderIcons(get(stayConnected, "icons"), "dark")}
        </div>
        <div className={cx("ama-sub-title", styles.description)}>
          {get(stayConnected, "description", "")}
        </div>
        <div className={cx(styles.groupSocial, styles.desktop)}>
          {renderIcons(get(stayConnected, "icons"))}
        </div>
        <div className={cx(styles.groupSocialDark, styles.desktop)}>
          {renderIcons(get(stayConnected, "icons"), "dark")}
        </div>
      </div>
      <div className={cx("inner-large", styles.box)}>
        {renderImages(slice(get(stayConnected, "images"), 0, 5))}
      </div>
      <div className={cx("inner-large", styles.box, styles.mobile)}>
        {renderImages(get(stayConnected, "images"))}
      </div>
    </section>
  );
};
export default View;
