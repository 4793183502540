import SEO from "./SEO";
import Zalo from "./Zalo";
import _ from "lodash";
const View = ({ children, config = { zaloShare: false } }: any) => {
  return (
    <>
      <SEO />
      {_.get(config, "zaloShare") && <Zalo />}
      {children}
    </>
  );
};
export default View;
